import logo from './logo.svg';
import './App.css';
import Selfie from './components/canvas/selfiePage';
// import MyApp from './components/canvas/selfiePage';


function App() {
  return (
    <div className="App">
    <Selfie />
    </div>
  );
}

export default App;
